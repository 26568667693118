import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slideshow.css';

const Slideshow = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: 'us-east-1',
      });

      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Prefix: 'runpod-store2/'
      };

      try {
        console.log('Fetching objects list from S3 with params:', params);
        const data = await s3.listObjectsV2(params).promise();
        console.log('Objects list fetched successfully:', data);

        const jobIdFolders = new Set();
        data.Contents.forEach(item => {
          const jobIdFolder = item.Key.split('/')[1];
          if (jobIdFolder) jobIdFolders.add(jobIdFolder);
        });

        const imageUrls = [];
        for (let jobId of jobIdFolders) {
          const folderParams = {
            Bucket: params.Bucket,
            Prefix: `${params.Prefix}${jobId}/`
          };

          console.log('Fetching images from folder with params:', folderParams);
          const folderData = await s3.listObjectsV2(folderParams).promise();
          console.log('Images fetched from folder:', folderData);

          folderData.Contents.forEach(item => {
            const url = s3.getSignedUrl('getObject', {
              Bucket: params.Bucket,
              Key: item.Key,
              Expires: 60 * 60
            });
            imageUrls.push(url);
          });
        }

        setImages(imageUrls);
        console.log('Image URLs set successfully:', imageUrls);
      } catch (error) {
        console.error('Error fetching images from S3:', error);
        setError(error.message);
      }
    };

    fetchImages();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false
  };

  return (
    <div className="slideshow-container">
      {error && <p className="error-message">Error: {error}</p>}
      <Slider {...settings}>
        {images.map((url, index) => (
          <div key={index} className="slide">
            <img src={url} alt={`Generated art ${index}`} className="slide-image" />
          </div>
        ))}
      </Slider>
      <div className="menu">
        <a href="/gallery">Gallery</a>
        <a href="/generate">Generate</a>
      </div>
    </div>
  );
};

export default Slideshow;
