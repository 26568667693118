import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css'; // Include your custom CSS
import workflow from './qualm-kingdom-workflow.json';
import Slideshow from './Slideshow';

function App() {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [jobInProgress, setJobInProgress] = useState(false);
  const myAPIKey = process.env.REACT_APP_MY_API_KEY;
  const [jobIds, setJobIds] = useState([]); // Changed to an array to store multiple job IDs

  const [currentImages, setCurrentImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [newJobIndexMap, setNewJobIndexMap] = useState({});
  const placeholderImage = `${process.env.PUBLIC_URL}/placeholder.png`; // Update with actual placeholder image path
  //const [customPrompt, setCustomPrompt] = useState('a beautiful anime illustration (Masterpiece, best quality:1.2)');

  //Prompt logic:

  const styles = [
    "an aerial photo",
    "a photo",
    "a street photography"
  ];

  const places = [
    "a suburban commercial zone with a gas station",
    "a modern shopping mall district",
    "a collapsed residential building",
    "a modern cinema multiplex district",
    "a suburban residential zone with modern buildings and a playground",
    "a playground in front of buildings in a green park",
    "nature",
    "a very rich villa area in the hills",
    "a collapsed ski resort",
    "an 80ies residential area",
    "a liminal space",
    "a destroyed outdoor water park",
    "a bleak suburban industrial zone",
    "a bleak suburban commercial zone",
    "a destroyed amusement park",
    "an airport",
    "a huge missile crater in a village",
    "a modern part in Zurich city",
    "an upper class suburb",
    "a bomb crater in a suburb",
    "a destroyed outdoor swimming pool"
  ];

  const places2 = [
    "in the suburbs of Zurich",
    "in Zurich city",
    "in the hills of Zurich",
    "in the mountains",
    "in the countryside",
    ""
  ];

  const destruction_levels = [
    "(some destruction:1.5)",
    "(heavy destruction:1.5)",
    "(heavy destruction and collapsed buildings:1.5)",
    "(heavy destruction and remains of destroyed tanks:1.4)",
    "(slight destruction:1.5)",
    "(some destruction:1.2)",
    "(heavy destruction and dead bodies:1.4)",
    "(heavy destruction:1.2)",
    "(heavy destruction and collapsed buildings:1.2)",
    "(heavy destruction and remains of destroyed tanks:1.2)",
    "(slight destruction:1.2)"
  ];

  const time_of_days = [
    "at sunset",
    "during sunset",
    "at dusk",
    "at magic hour",
    "foggy morning",
    "snowy winter",
    "in the summer",
    "harsh sunlight",
    "at night"
  ];

  const template = "swtzlnd style. $style of $place $place2 in swtzlnd in Europe after a war in 2023. $destruction_level. $time_of_day. Something is eerie. Real, 8k uhd, realistic, documentary photography, journalism. Film grain";


  const generatePrompt = () => {
    const style = styles[Math.floor(Math.random() * styles.length)];
    const place = places[Math.floor(Math.random() * places.length)];
    const place2 = places2[Math.floor(Math.random() * places2.length)];
    const destruction_level = destruction_levels[Math.floor(Math.random() * destruction_levels.length)];
    const time_of_day = time_of_days[Math.floor(Math.random() * time_of_days.length)];

    const prompt = template.replace("$place", place)
      .replace("$style", style)
      .replace("$place2", place2)
      .replace("$destruction_level", destruction_level)
      .replace("$time_of_day", time_of_day);
    return prompt;
  }


  // Function to replace seeds with random values
  const replaceSeedValuesWithRandom = (obj) => {
    Object.keys(obj).forEach(key => {
      if (key === 'seed' && typeof obj[key] === 'number') {
        obj[key] = Math.floor(Math.random() * 1000000000); // Random seed
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceSeedValuesWithRandom(obj[key]); // Recurse into nested objects
      }
    });
  };

  const generateArt = async () => {
    setIsLoading(true);
    setJobInProgress(true);
    const newJobIds = [];
    const newJobIndexMap = {};

    // Move current images to the gallery
    setGalleryImages(prevGallery => [...prevGallery, ...currentImages.map(image => ({ ...image }))]);
    setCurrentImages([]);

    // Reset placeholders with initial status and progress
    const placeholders = new Array(1).fill().map(() => ({
      url: placeholderImage,
      metadata: null,
      progress: 0,
      status: 'waiting',
    }));

    setCurrentImages(placeholders);

    let response;
    const comfyPayload = {
      input: {
        handler: "RawWorkflow",
        aws_access_key_id: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        aws_secret_access_key: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        aws_endpoint_url: "https://runpod-store2.s3.us-east-1.amazonaws.com",
        aws_bucket_name: process.env.REACT_APP_AWS_BUCKET_NAME,
        webhook_url: process.env.REACT_APP_WEBHOOK_URL,
        workflow_json: JSON.parse(JSON.stringify(workflow)),
        policy: {
          executionTimeout: 900000,
        }
      }
    };

    // Randomize seeds in the workflow JSON
    replaceSeedValuesWithRandom(comfyPayload.input.workflow_json);

    // Generate and set the custom prompt
    const customPrompt = generatePrompt();
    if (comfyPayload.input.workflow_json['6'] && comfyPayload.input.workflow_json['6'].inputs) {
      comfyPayload.input.workflow_json['6'].inputs.text = customPrompt;
      console.log("Prompt sent to API:", customPrompt); // Log the prompt
    } else {
      console.error("Invalid workflow_json structure: '6' key missing or incorrect.");
      setIsLoading(false);
      return;
    }

    try {
      response = await axios.post(`https://api.runpod.ai/v2/pud8zbiwdvz6vu/run`, comfyPayload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`
        },
      });
      console.log("Received response from ComfyUI API", response.data);

      if (response.data && response.data.id) {
        newJobIds.push({ id: response.data.id, backendType: 'comfy' });
        newJobIndexMap[response.data.id] = { index: 0 };
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setJobInProgress(false);
    } finally {
      setJobIds(prevJobIds => [...prevJobIds, ...newJobIds]);
      setNewJobIndexMap(prevMap => ({ ...prevMap, ...newJobIndexMap }));
      setIsLoading(false);
    }
  };


  const checkJobStatus = async (job) => {
    try {
      const endpoint = `https://api.runpod.ai/v2/pud8zbiwdvz6vu/status/${job.id}`;
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`
        },
      });

      console.log(`Received status update for job ID ${job.id}: `, response.data);
      setApiStatus(response.data.status);

      if (response.data.status === 'COMPLETED' && response.data.output && response.data.output.images) {
        const imageOutput = response.data.output.images[0];
        if (imageOutput && imageOutput.url) {
          setImageUrl(imageOutput.url); // Update state with the image URL
        }
      }

      if (['COMPLETED', 'FAILED'].includes(response.data.status)) {
        setIsLoading(false);
        setJobInProgress(false);
        return response.data.status === 'COMPLETED' ? job.id : null;
      }
    } catch (err) {
      console.error("Error checking job status:", err);
      setIsLoading(false);
      setJobInProgress(false);
      return null;
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const completedJobIds = await Promise.all(
        jobIds.map(async (job) => await checkJobStatus(job))
      );

      // Filter out nulls and failed jobs
      const idsToRemove = completedJobIds.filter(id => id !== null);
      if (idsToRemove.length > 0) {
        setJobIds(prevJobIds => prevJobIds.filter(job => !idsToRemove.includes(job.id)));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [jobIds]);

  return (
    <Router>
      <div className="container my-5">
        <h1 className="text-center display-4 mb-4">QUALM KINGDOM</h1>
        <div className="d-flex flex-column align-items-center">
          <div className="mb-4">
            <button
              onClick={generateArt}
              className={`btn btn-lg ${isLoading ? 'btn-secondary' : 'btn-dark'}`}
              disabled={jobInProgress}
            >
              {jobInProgress ? 'Generating...' : 'Generate'}
            </button>
          </div>
          <div className="mb-4">
            <a
              href="https://foundation.app/collection/qkd"
              className="btn btn-lg btn-dark-blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mint
            </a>
          </div>
          {imageUrl && (
            <div className="image-preview-container mb-3">
              <img src={imageUrl} alt="Generated Art" className="generated-image" />
            </div>
          )}
          {apiStatus && (
            <p className="text-center mb-3">{`Status: ${apiStatus}`}</p>
          )}

        </div>
        <Routes>
          <Route path="/slideshow" element={<Slideshow />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;